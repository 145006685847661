<div class="form-group">
  <label *ngIf="label" for="{{ clsfId }}"
    >{{ label }}<span *ngIf="requiredField || showJustRequiredLabel" class="required"> *</span></label
  >
  <select
    #clsfSelect="ngModel"
    id="{{ clsfId }}"
    name="{{ clsfName }}"
    (click)="handleClick($event)"
    [disabled]="disabledField"
    [(ngModel)]="selected"
    (ngModelChange)="handleSelect()"
    [required]="requiredField"
    [ngClass]="{
      'is-invalid': clsfSelect.touched && clsfSelect.errors,
      'is-valid': clsfSelect.valid
    }"
  >
    <option [ngValue]="null">{{ placeholder }}</option>
    <option [ngValue]="option.id" *ngFor="let option of options">
      {{ option.name }}
    </option>
  </select>
  @if(clsfSelect.invalid && (clsfSelect.dirty || clsfSelect.touched)){
    <div class="text-danger">
      @if(clsfSelect.errors?.['required']){
        <div>{{ label }} yra privalomas.</div>
      }
    </div>
  }
</div>
