import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Classifiers } from '../classifiers.enum';
import { ClassifiersService } from '../classifiers.service';
import { ClsfDropdownItem, ClsfBaseResponseDto } from '../clsf-dto';

@Component({
  selector: 'app-clsf-dropdown',
  templateUrl: './clsf-dropdown.component.html',
  styleUrls: ['./clsf-dropdown.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClsfDropdownComponent),
      multi: true,
    },
  ],
})
export class ClsfDropdownComponent implements ControlValueAccessor, OnInit {
  @Input()
  clsfType?: Classifiers;

  @Input()
  filter: any = null;

  @Input()
  clsfName!: string;

  @Input()
  clsfId!: string;

  @Input()
  disabledField: boolean = false;

  @Input()
  selected: number | null = null;

  @Input()
  label: string = '';

  @Input()
  placeholder: string = 'Pasirinkite';

  @Input()
  options: ClsfDropdownItem[] = [];

  @Input()
  requiredField: boolean = false;

  @Input()
  showJustRequiredLabel: boolean = false;

  @Input()
  selectKey: 'id' | 'code' = 'id';

  private onTouched!: Function;

  private onChanged!: Function;

  constructor(private service: ClassifiersService) {
  }

  ngOnInit(): void {
    if (this.clsfType) {
      this.clsfId = Classifiers[ this.clsfType ] + 'Id';
      this.clsfName = Classifiers[ this.clsfType ] + 'Id';
    }

    if (this.selected) {
      this.getClsfOptionsData();
    }
  }

  writeValue(value: number): void {
    if (value) {
      this.getClsfOptionsData();
    }
    this.selected = value;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getClsfOptionsData() {
    if (this.options.length === 0 && this.clsfType) {
      this.service.getList(this.clsfType, this.filter).subscribe((resp: ClsfBaseResponseDto[]) => {
        if (this.selectKey === 'code') {
          this.options = resp.map((item) => ({
            id: item.code,
            name: item.name,
          }));
        } else {
          this.options = resp;
        }
      });
    }
  }

  setDisabledState(isDisabled: boolean) {
    this.disabledField = isDisabled;
  }

  handleClick(event: Event) {
    this.getClsfOptionsData();
  }

  handleSelect() {
    this.onTouched?.();
    this.onChanged?.(this.selected);
  }
}
